import React from "react"
import Cookies from 'universal-cookie';

function handleClick(e) {
  console.log('Test');

  const currentForm = document.getElementById("bookDemoForm")

  if (currentForm.checkValidity() == true) {
    e.target.disabled = true;
    e.target.innerText = "Submitting...";
    currentForm.submit();
  }
}

const BookDemoForm = () => {

  const cookies = new Cookies();

  return (
  <form
    action="https://getform.io/f/8a0a572e-0458-41c6-9b33-922278a38514"
    method="POST"
    id="bookDemoForm"
  >
    <input
      name="Name"
      type="text"
      placeholder="Name"
      className="input-alt w-full mt-10"
      required
    />
    <input
      name="Surname"
      type="text"
      placeholder="Surname"
      className="input-alt w-full mt-2"
      required
    />
    <input
      name="EmailDemoFrom"
      type="text"
      placeholder="Email"
      className="input-alt w-full mt-2"
      required
    />
    <input
      name="ContactNumber"
      type="text"
      placeholder="Contact Number"
      className="input-alt w-full mt-2"
      required
      inputmode="numeric" pattern="[0-9]{10}"
    />
    <input
      name="CompanyName"
      type="text"
      placeholder="Company Name"
      className="input-alt w-full mt-2"
      required
    />

    <div className="inline-block relative w-full mt-2">
      <select
        name="Business Type"
        id="company_type"
        className="appearance-none input-alt w-full"
      >
        <option value="" disabled selected hidden>
          Select your business type
        </option>
        <option value="estate_agency">Estate Agency</option>
        <option value="property_developer">Property Developer</option>
        <option value="managing_agent">Managing Agent</option>
        <option value="landlord">Landlord</option>
        <option value="property_fund">Property Fund</option>
      </select>
      <div className="pointer-events-none h-full absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
        <i className="w-full self-center fas fa-caret-down"></i>
      </div>
    </div>

    <div className="inline-block relative w-full mt-2">
      <select
        name="Number of properties"
        id="num_properties
        "
        className="appearance-none input-alt w-full"
      >
        <option value="" disabled selected hidden>
          How many properties in your portfolio?
        </option>
        <option value="1-10">1-10</option>
        <option value="10-100">10-100</option>
        <option value="100-500">100-500</option>
        <option value="500+">500+</option>
      </select>
      <div className="pointer-events-none h-full absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
        <i className="w-full self-center fas fa-caret-down"></i>
      </div>
    </div>

    <input placeholder="agency_slug" type="text" name="agency_slug" className="hidden" value={cookies.get('agency_slug')}></input>
    <input placeholder="organisation_id" type="text" name="organisation_id" className="hidden" value={cookies.get('organisation_id')}></input>
    <input placeholder="utm_source" type="text" name="utm_source" className="hidden" value={cookies.get('utm_source')}></input>
    <input placeholder="utm_medium" type="text" name="utm_medium" className="hidden" value={cookies.get('utm_medium')}></input>
    <input placeholder="utm_campaign" type="text" name="utm_campaign" className="hidden" value={cookies.get('utm_campaign')}></input>
    <input placeholder="utm_content" type="text" name="utm_content" className="hidden" value={cookies.get('utm_content')}></input>
  
    <p className="text-md font-bold text-gray-800 mt-8">What are you looking to achieve with Flow?</p>
    
    <div class="relative flex items-start my-2">
      <div class="flex items-center h-5">
        <input id="increase_listing" name="Increase Listing" type="checkbox" class="h-4 w-4 text-orange-500 border-gray-300 rounded"/>
      </div>
      <div class="ml-3 text-sm">
        <label for="increase_listing" class="font-medium text-gray-800">I want to increase my listing portfolio</label>
      </div>
    </div>

    <div class="relative flex items-start my-2">
      <div class="flex items-center h-5">
        <input id="increase_buyer_renter" name="Increase Buyer Renter" type="checkbox" class="h-4 w-4 text-orange-500 border-gray-300 rounded"/>
      </div>
      <div class="ml-3 text-sm">
        <label for="increase_buyer_renter" class="font-medium text-gray-800">I want to increase buyer/renter leads</label>
      </div>
    </div>

    <div class="relative flex items-start my-2">
      <div class="flex items-center h-5">
        <input id="top_of_mind" name="Top of Mind" type="checkbox" class="h-4 w-4 text-orange-500 border-gray-300 rounded"/>
      </div>
      <div class="ml-3 text-sm">
        <label for="top_of_mind" class="font-medium text-gray-800">I want to be top of mind in key regions</label>
      </div>
    </div>

    <div class="relative flex items-start my-2">
      <div class="flex items-center h-5">
        <input id="top_performing_agents" name="Top Performing Agents" type="checkbox" class="h-4 w-4 text-orange-500 border-gray-300 rounded"/>
      </div>
      <div class="ml-3 text-sm">
        <label for="top_performing_agents" class="font-medium text-gray-800">I want to find top performing agents to join my team</label>
      </div>
    </div>

    <div class="relative flex items-start mt-8">
      <div class="flex items-center h-5">
        <input id="subsribe" name="Proptech Subscribe" type="checkbox" class="h-4 w-4 text-orange-500 border-gray-300 rounded"/>
      </div>
      <div class="ml-3 text-sm">
        <label for="subsribe" class="font-medium text-gray-800">Subscribe to Proptech news from Flow</label>
      </div>
    </div>

    <p className="text-xxs text-gray-500 mt-4">By submitting I agree to allow Flow to collect, store and process my data, as per the <a href="/privacypolicy" target="_blank" className="text-orange-500 underline">Privacy Policy</a></p>

    <button onClick={handleClick} type="submit" className="btn-primary mt-2 w-full">
      Book a demo
    </button>
  </form>
)}

export default BookDemoForm
