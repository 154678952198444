import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import BookDemoForm from "../components/book_demo_form"
import landlord_header from "../images/landlord_header.png"
import ephraimzaslansky from "../images/testimonials/ephraimzaslansky.png"
import firzt from "../images/logos/properties/firzt.png"

import wave from "../images/wave.svg"

const BookDemo = () => (
  <Layout>
    <SEO
      title="Book a Demo"
      description="You have to see what Flow can do for you"
      image={landlord_header}
    />

    <section className="md:self-center container mx-auto bg-white w-full mt-10 rounded-xl relative flex flex-col md:flex-row md:mt-12">
      <div className="w-full md:w-1/2 px-10">
        <h2 className="text-2xl md:text-4xl text-left font-bold text-gray-700 leading-tight md:mt-0">
          You have to see what <span className="wrap">Flow</span> can do for you
        </h2>

        <BookDemoForm />
      </div>

      <div className="w-full md:w-1/2 bg-gray-100 rounded-xl p-10 mt-10 md:mt-0">
        <div className="flex w-full">
          <div className="mt-4 leading-none">
            <div className="rounded-full bg-orange-500 border-4 border-orange-200 h-16 w-16 p-1 mr-4 flex-shrink-0">
              <img className="w-9 h-9" src={ephraimzaslansky} alt="" />
            </div>
            <img
              className="self-center h-8 mt-2 rounded-lg mr-auto"
              src={firzt}
              alt=""
            />
          </div>
          <p className="font-medium tracking-tight text-gray-700 text-sm bg-white p-4 rounded-lg">
            "We are of course marketing on social media, but we didn’t know we
            could be doing it so much more effectively! Using the automated Flow
            platform is like having a dedicated social media marketing
            department working solely on our digital presence" 
            <b> Ephraim Zaslansky - MD, Firzt Realty</b>
          </p>
        </div>

        <p className="text-md md:text-xl text-center md:text-left font-bold text-gray-700 max-w-md mt-10">
          Let’s do a demo where we’ll show you:
        </p>

        <div className="w-full flex text-left mt-10">
          <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
            <i className="w-full self-center fab fa-facebook"></i>
          </div>
          <div>
            <h3 className="font-bold text-gray-700 ">
              Automatically creating ads out of your listings
            </h3>
            <p className="font-light text-sm text-gray-400 mr-4">
              How Flow pulls in your listings and agent profiles and promotes
              them as Facebook and Instagram ads.
            </p>
          </div>
        </div>

        <div className="w-full flex text-left mt-5">
          <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
            <i className="w-full self-center fas fa-user-check"></i>
          </div>
          <div>
            <h3 className="font-bold text-gray-700 ">
              How your profile and listings are targeted
            </h3>
            <p className="font-light text-sm text-gray-400 mr-4">
              An overview of who would see your ads, in which areas and how
              often.
            </p>
          </div>
        </div>

        <div className="w-full flex text-left mt-5">
          <div className="flex inline-block w-10 h-10 rounded-full bg-orange-500 text-white flex-shrink-0 text-center mr-6">
            <i className="w-full self-center fas fa-project-diagram"></i>
          </div>
          <div>
            <h3 className="font-bold text-gray-700 ">
              Access to your account and campaign info
            </h3>
            <p className="font-light text-sm text-gray-400 mr-4">
              An overview of your Flow dashboard, campaign and targeting info and
              your real-time analytics.
            </p>
          </div>
        </div>
      </div>
    </section>

    <img className="w-full mt-10" src={wave} alt="" />

    <Footer />
  </Layout>
)

export default BookDemo
